<template>
  <div class="px-3">
    <h2 class="text-white ml-4 mb-4">
      Gestão de Fanbox:

      <img
        class="ml-2"
        :src="imgpath(selectedOwner)"
        height="60"
      >
    </h2>

    <p
      class="text-white"
      style="cursor: pointer"
      @click="goBackToIndex"
    >
      <i class="fa fa-arrow-left mr-2" />

      Voltar
    </p>

    <vs-table
      v-if="boxes.length > 0"
      :data="boxes"
      search
      class="mb-3"
    >
      <template slot="header">
        <div class="d-flex w-100 justify-content-between px-2">
          <h2 class="text-dark ml-2">
            Boxes
          </h2>

          <vs-button
            color="#66ff66"
            text-color="black"
            class="mr-2"
            icon="add"
            @click="createNewBox"
          >
            Adicionar Box
          </vs-button>
        </div>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          v-for="(box, boxIdx) in data"
          :key="boxIdx"
          class="border-top mt-2"
        >
          <div
            class="d-flex w-100 justify-content-between"
            draggable
            @dragstart="pickupItem($event, boxIdx)"
            @drop="moveItem($event, boxIdx)"
            @dragover.prevent
            @dragenter.prevent
          >
            <vs-td class="py-1 w-75">
              {{ box.title }}
            </vs-td>

            <vs-td class="py-1 w-25">
              <p class="w-100 text-center">
                <span v-if="!box.filterGroup">
                  Sem categoria
                </span>

                <span v-else>
                  {{ getFilterGroupName(box.filterGroup) }}
                </span>
              </p>
            </vs-td>

            <vs-td class="py-1">
              <vs-button
                small
                icon="list_alt"
                @click="editBox(box)"
              />
            </vs-td>
          </div>
        </vs-tr>
      </template>
    </vs-table>

    <vs-popup
      fullscreen
      title="Editar Box"
      :active.sync="editingBox"
      :button-close-hidden="true"
    >
      <div class="h-100 d-flex flex-column justify-content-between mx-5">
        <div class="d-flex justify-content-between">
          <h2 v-if="!editingBoxName">
            <small>Box selecionada:</small>

            {{ selectedBox.title }}

            <i
              class="mdi mdi-pen"
              style="font-size: 1.3rem; cursor: pointer"
              @click="editingBoxName = true"
            />
          </h2>

          <vs-row v-else>
            <vs-col vs-lg="6">
              <label class="small font-weight-bold mt-2">
                Título:
              </label>

              <vs-input
                v-model="selectedBox.title"
                placeholder="Título do Box"
                class="w-100"
              />
            </vs-col>

            <vs-col vs-lg="6">
              <label class="small font-weight-bold mt-2">
                Nome da Rota:
              </label>

              <vs-input
                v-model="selectedBox.name"
                placeholder="Nome da Rota"
                class="w-100"
              />
            </vs-col>
          </vs-row>

          <div class="text-right">
            <vs-button
              size="large"
              color="black"
              class="mr-2 px-4"
              @click="discardChanges"
            >
              Cancelar
            </vs-button>

            <vs-button
              size="large"
              :color="cor2"
              class="px-4"
              @click="saveBoxes"
            >
              Salvar
            </vs-button>
          </div>
        </div>

        <div class="d-flex my-4 h-100">
          <!-- indicators avaliable  -->
          <div class="w-50 pr-5">
            <div class="text-left">
              <h3 class="mb-2">
                Categoria do Box:
              </h3>

              <div class="d-flex w-100 flex-column align-items-start">
                <vs-radio
                  v-for="(group, i) in groups"
                  :key="i"
                  v-model="selectedBox.filterGroup"
                  :vs-value="group.id"
                >
                  {{ group.group }}
                </vs-radio>
              </div>
            </div>

            <vs-tabs class="mt-4">
              <vs-tab label="Indicadores não utilizados">
                <vs-table
                  v-if="avaliableIndicators.length > 0"
                  :data="avaliableIndicators"
                  search
                >
                  <template slot="header">
                    <h3 class="my-4">
                      Indicadores não utilizados:
                    </h3>
                  </template>

                  <template slot="thead">
                    <vs-th>Nome</vs-th>

                    <vs-th>ID</vs-th>

                    <vs-th>Código</vs-th>

                    <vs-th>Incluir</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      v-for="(indicator, indIdx) in data"
                      :key="indIdx"
                      class="border-top"
                    >
                      <vs-td class="py-1">
                        {{ indicator.description }}
                      </vs-td>

                      <vs-td class="py-1">
                        {{ indicator.indId }}
                      </vs-td>

                      <vs-td class="py-1">
                        {{ indicator.indCode }}
                      </vs-td>

                      <vs-td class="py-1">
                        <vs-button
                          size="small"
                          text-color="black"
                          color="secondary"
                          icon="add"
                          @click="addIndicatorToBox(indicator)"
                        />
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-tab>

              <vs-tab label="Todos os indicadores">
                <vs-table
                  v-if="indicators.length > 0"
                  :data="indicators"
                  search
                >
                  <template slot="header">
                    <h3 class="my-4">
                      Todos os indicadores:
                    </h3>
                  </template>

                  <template slot="thead">
                    <vs-th>Nome</vs-th>

                    <vs-th>ID</vs-th>

                    <vs-th>Código</vs-th>

                    <vs-th>Incluir</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      v-for="(indicator, indIdx) in data"
                      :key="indIdx"
                      class="border-top"
                    >
                      <vs-td class="py-1">
                        {{ indicator.description }}
                      </vs-td>

                      <vs-td class="py-1">
                        {{ indicator.indId }}
                      </vs-td>

                      <vs-td class="py-1">
                        {{ indicator.indCode }}
                      </vs-td>

                      <vs-td class="py-1">
                        <vs-button
                          size="small"
                          text-color="black"
                          color="secondary"
                          icon="add"
                          @click="addIndicatorToBox(indicator)"
                        />
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-tab>
            </vs-tabs>
          </div>

          <!-- Selected Indicators -->
          <div
            class="w-50 pl-5"
            style="border-left: 2px solid #d8d8d8"
          >
            <h3 class="mb-2">
              Indicadores do box:
            </h3>

            <div class="c-card align-items-center">
              <h4 v-if="!selectedBox.indexes || !selectedBox.indexes.length">
                Nenhum indicador selecionado.
              </h4>

              <div
                v-else
                class="d-flex flex-wrap align-items-center"
              >
                <vs-chip
                  v-for="(indicator, indIdx) in selectedBox.indexes"
                  :key="indIdx"
                  class="w-auto"
                  :color="cor1"
                  closable
                  @click="removeIndicatorFromBox(indicator.idxCode)"
                >
                  <div
                    class="mb-1"
                    draggable
                    @dragstart="pickupItem($event, indIdx)"
                    @drop="moveIndicator($event, indIdx, selectedBox)"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    {{ indicator.idxText }} ({{ indicator.idxCode }})

                    <i
                      class="mdi ml-2"
                      :class="cardTypeIcon(indicator.CardType)"
                      style="font-size: 1.3rem"
                    />
                  </div>
                </vs-chip>
              </div>
            </div>

            <div
              v-if="selectedBox.indexes && selectedBox.indexes.length"
              :key="increment"
              class="mt-5 text-center"
            >
              <h3 class="mb-2">
                Configuração de visualização:
              </h3>

              <div
                v-for="(indicator, indIdx) in selectedBox.indexes"
                :key="indIdx"
                class="mx-auto d-flex align-items-center justify-content-between py-2 w-100"
                style="border-bottom: 1px solid #d5d5d5"
              >
                <p class="mr-2 mb-0 text-left w-25">
                  {{ indicator.idxText }}:

                  <b>{{ indicator.cardType }}</b>
                </p>

                <div>
                  <button
                    v-for="(cardType, ctIdx) in cardTypes"
                    :key="ctIdx"
                    class="reseted-btn mr-2"
                    :style="
                      indicator.cardType === cardType.type
                        ? { backgroundColor: 'rgb(var(--vs-secondary))' }
                        : {}
                    "
                    @click="indicator.cardType = cardType.type"
                  >
                    <i
                      class="mdi"
                      :class="cardType.class"
                      style="font-size: 1.3rem"
                    />
                  </button>
                </div>

                <div class="d-flex align-items-center">
                  <p class="mr-2 mb-0 text-left">
                    É filtro:
                  </p>

                  <button
                    class="reseted-btn mr-2"
                    :style="
                      !indicator.noFilter
                        ? { backgroundColor: 'rgb(var(--vs-secondary))' }
                        : {}
                    "
                    @click="setFilterStatus(indicator)"
                  >
                    <i
                      class="mdi mdi-filter"
                      style="font-size: 1.3rem"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      title="Adicionar novo Box"
      :active.sync="creatingBox"
    >
      <vs-row>
        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">
            Título:
          </label>

          <vs-input
            v-model="newBox.title"
            placeholder="Título do Box"
            class="w-100"
          />
        </vs-col>

        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">
            Nome da Rota:
          </label>

          <vs-input
            v-model="newBox.name"
            placeholder="Nome da Rota"
            class="w-100"
          />
        </vs-col>
      </vs-row>

      <div class="d-flex justify-content-end mt-3">
        <vs-button
          class="mr-2"
          @click="a"
        >
          Cancelar
        </vs-button>

        <vs-button
          color="#66ff66"
          text-color="black"
          @click="saveNewBox"
        >
          Criar Box
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Services */
import { baseUrl, configDomain } from '@/global';
import Survey from '@/services/survey';

import axios from 'axios';
const surveyService = new Survey();


export default {
  name: 'FanboxConfig',
  props: {
    selectedOwner: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    boxes: [],
    groups: [],
    cardTypes: [
      { type: 'Lista', class: 'mdi-format-list-numbered-rtl' },
      { type: 'Browse', class: 'mdi-format-list-bulleted-type' },
      { type: 'Donut', class: 'mdi-chart-donut' },
      { type: 'SuperFans', class: 'mdi-star-circle' },
      { type: 'Hidden', class: 'mdi-eye-off' },
    ],
    indicators: [],
    creatingBox: false,
    editingBox: false,
    editingBoxName: false,
    selectedBox: {},
    newBox: {},
    increment: 0,
  }),
  computed: {
    cor1() {
      return this.$store.state.configBox.cor1;
    },
    cor2() {
      return this.$store.state.configBox.cor2;
    },
    takenIndicators() {
      let taken = [];
      for (let i = 0; i < this.boxes.length; i++) {
        this.boxes[i].indexes.map((index) => {
          taken.push(index);
        });
      }
      return taken;
    },
    avaliableIndicators() {
      let avaliable = [];
      avaliable = this.indicators.filter((indicator) => {
        let includeThisIndicator = true;
        this.takenIndicators.forEach((taken) => {
          if (indicator.indCode === taken.idxCode) includeThisIndicator = false;
        });
        return includeThisIndicator;
      });
      return avaliable;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  watch: {
    editingBox(newValue) {
      // if for some reason the user closes the box editor without using the cancel button,
      // this function reloads the boxes to discard unsaved changes.
      if (!newValue) {
        this.getBoxes();
      }
    },
  },
  mounted() {
    this.getGroups();

    this.getIndicators();

    this.getBoxes();
  },
  methods: {
    pickupItem(e, itemIndex) {
      e.dataTransfer.effectAllowed = 'move';

      e.dataTransfer.dropEffect = 'move';

      e.dataTransfer.setData('item-index', itemIndex);
    },
    moveItem(e, toIndex) {
      const fromIndex = e.dataTransfer.getData('item-index');

      const itemToMove = this.boxes.splice(fromIndex, 1)[0];

      this.boxes.splice(toIndex, 0, itemToMove);

      this.saveBoxes();
    },
    moveIndicator(e, toIndex, source) {
      const fromIndex = e.dataTransfer.getData('item-index');

      const itemToMove = source.indexes.splice(fromIndex, 1)[0];

      source.indexes.splice(toIndex, 0, itemToMove);
    },
    getGroups() {
      const url = `${baseUrl}/dashconfig/filtergroups`;

      axios
        .get(url)
        .then((res) => {
          this.groups = res.data.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    getBoxes() {
      this.$store.dispatch('set_isLoadingActive', true);

      const url = `${baseUrl}/dashconfig/boxes/${this.selectedOwner}`;

      axios
        .get(url)
        .then((res) => {
          this.boxes = res.data.data.boxes;

          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
    getIndicators() {
      this.$store.dispatch('set_isLoadingActive', true);
      surveyService.getIndicators(this.selectedOwner, this.token)
        .then((res) => {
          this.indicators = res;

          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
    getFilterGroupName(code) {
      let filterGroup = null;

      if (this.boxes && this.groups) {
        filterGroup = this.groups.find((el) => {
          return el.id == code;
        });
      }

      if (filterGroup) return filterGroup.group;

      return '';
    },
    createNewBox() {
      this.creatingBox = !this.creatingBox;

      this.newBox = {};
    },
    saveNewBox() {
      this.newBox.indexes = [];

      this.boxes.push(this.newBox);

      this.newBox = {};

      this.createNewBox();
      this.saveBoxes();
    },
    saveBoxes() {
      let obj = {};

      obj.OwnerId = this.selectedOwner;
      obj.SysId = 101;
      obj.Data = {};
      obj.Data.boxes = this.boxes;

      const url = `${baseUrl}/dashconfig/saveboxes`;

      axios
        .post(url, obj)
        .then(() => {
          this.editingBox = false;

          this.editingBoxName = false;

          this.getBoxes();
        })
        .catch((err) => {
          alert(err);
        });
    },
    editBox(selectedBox) {
      this.selectedBox = selectedBox;

      this.editingBox = true;
    },
    addIndicatorToBox(indicator) {
      let obj = {};

      obj.idxCode = indicator.indCode;
      obj.idxText = indicator.description;
      obj.idxType = 'Lista';
      obj.cardType = 'Lista';

      this.selectedBox.indexes.push(obj);
    },
    removeIndicatorFromBox(idxCode) {
      this.selectedBox.indexes = this.selectedBox.indexes.filter((el) => {
        return el.idxCode != idxCode;
      });
    },
    setFilterStatus(indicator) {
      if (!indicator.noFilter) {
        indicator.noFilter = true;
      } else {
        indicator.noFilter = false;
      }

      this.increment++;
    },
    cardTypeIcon(type) {
      let cardType = this.cardTypes.find((el) => {
        return el.type === type;
      });

      if (!cardType) return;

      return cardType.class;
    },
    discardChanges() {
      this.editingBox = false;

      this.editingBoxName = false;

      this.getBoxes();
    },
    goBackToIndex() {
      this.$router.push({
        name: 'FanboxManagement',
        params: { selectedOwner: this.selectedOwner },
      });
    },
    imgpath(id) {
      return `${configDomain}/sys/101/${id}/logo.png`;
    },
  },
};
</script>

<style>
.c-card {
  border: 1px solid #505050;
  border-radius: 5px;
  padding: 10px 10px;
  max-width: 100%;
}

.reseted-btn {
  height: 30px;
  width: 30px;
  background-color: transparent;
  border-radius: 50%;
  border: 0px;
  font-size: 1.3rem;
  font-weight: 700;
  background-color: #c0c0c0;
}

.reseted-btn:hover {
  background-color: rgb(var(--vs-warning)) !important;
}
</style>
